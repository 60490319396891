import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import React from 'react'
import { Breadcrumb, BreadcrumbItem } from '../components/Breadcrumb'
import { ContactInfo } from '../components/ContactInfo'
import { ContactForm } from '../components/forms/ContactForm'
import { GoogleMapsEmbed } from '../components/GoogleMapsEmbed'
import { PageH1 } from '../components/PageH1'
import { WidthWrapper } from '../components/WidthWrapper'
import { ContactPageQuery } from '../types/graphql'
import { cx } from '../utils/helpers'

export const query = graphql`
  query ContactPageQuery {
    datoCmsContactPage {
      seoMetaTags {
        tags
      }
    }
  }
`

type Props = {
  data: ContactPageQuery
}

const ContactPage: React.FC<Props> = ({ data }) => {
  const { seoMetaTags } = data.datoCmsContactPage!

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />

      <main>
        <Breadcrumb>
          <BreadcrumbItem>Contact Us</BreadcrumbItem>
        </Breadcrumb>

        <PageH1>Contact Us</PageH1>

        <WidthWrapper>
          <section className="my-8 pb-4">
            <div className="mt-8 md:flex bg-white shadow-lg rounded-lg p-2 border-b-2 border-brand">
              <ContactInfo className="md:w-1/2 p-6" />

              <div
                className={cx(
                  'mt-8 rounded-lg shadow-lg overflow-hidden',
                  'md:w-1/2 md:m-0',
                )}
              >
                <GoogleMapsEmbed className="h-64 md:h-full" />
              </div>
            </div>
          </section>

          <section className="my-8 pt-8 md:my-12 md:pt-12 border-t border-gray-300">
            <h2 className="text-2xl text-accent-800 leading-tight">
              Leave a message.
            </h2>

            <div className="mt-8 max-w-2xl">
              <ContactForm />
            </div>
          </section>
        </WidthWrapper>
      </main>
    </>
  )
}

export default ContactPage
