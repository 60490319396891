import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { FaxQuery } from '../../types/graphql'

type Props = {
  withAlternate?: boolean
}

const query = graphql`
  query FaxQuery {
    datoCmsCompanyInformation {
      fax
      faxAlternate
    }
  }
`

export function Fax({ withAlternate = false }: Props): React.ReactElement {
  const data = useStaticQuery<FaxQuery>(query)
  const { fax, faxAlternate } = data.datoCmsCompanyInformation!
  return (
    <>
      <p>{fax}</p>

      {withAlternate && <p>{faxAlternate}</p>}
    </>
  )
}
