import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { GoogleMapsEmbedQuery } from '../types/graphql'

const query = graphql`
  query GoogleMapsEmbedQuery {
    datoCmsCompanyInformation {
      googleMapsEmbedLink
    }
  }
`

type Props = React.IframeHTMLAttributes<HTMLIFrameElement>

export function GoogleMapsEmbed(props: Props): React.ReactElement {
  const data = useStaticQuery<GoogleMapsEmbedQuery>(query)
  const { googleMapsEmbedLink } = data.datoCmsCompanyInformation!

  return (
    <iframe
      src={googleMapsEmbedLink!}
      allowFullScreen
      width="100%"
      title="Embedded Google Maps centered at PT. Unggul Semesta office"
      {...props}
    />
  )
}
