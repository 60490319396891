import { css } from '@emotion/core'
import { submitFormToNetlify } from 'netlify-form-submit'
import React from 'react'
import { FieldList } from '../../types'
import { useForm } from '../../utils/useForm'
import { Alert } from '../Alert'
import { Input } from './Input'
import { Label } from './Label'
import { NetlifyHoneypot } from './NetlifyHoneypot'
import { SubmitButton } from './SubmitButton'

const NETLIFY_BOT_FIELD_NAME = 'bot-field'
const FORM_NAME = 'contact'

type Values = Record<'name' | 'company' | 'phone' | 'email' | 'message', string>

const initialValues: Values = {
  name: '',
  company: '',
  phone: '',
  email: '',
  message: '',
}

const fields: FieldList<keyof Values> = [
  {
    name: 'name',
    type: 'text',
    label: 'Name',
    placeholder: 'Your name',
    required: true,
  },
  {
    name: 'company',
    type: 'text',
    label: 'Company / Organization',
    placeholder: 'Your company / org. name',
    required: false,
  },
  {
    name: 'phone',
    type: 'tel',
    label: 'Phone',
    placeholder: 'Your phone',
    required: true,
  },
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    placeholder: 'Your email address',
    required: true,
  },
  {
    name: 'message',
    type: 'textarea',
    label: 'Message',
    placeholder: 'Your message',
    required: true,
  },
]

export const ContactForm: React.FC = () => {
  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    setIsSubmitting,
    submissionState,
    setSubmissionState,
    resetForm,
  } = useForm<Values>({
    initialValues,
    onSubmit,
  })

  async function onSubmit() {
    setIsSubmitting(true)

    // AJAX request to Netlify form handling
    await submitFormToNetlify({
      'form-name': FORM_NAME,
      ...values,
    })
      .then(() => {
        setSubmissionState({
          status: 'SUCCESS',
          message: <>Thank you. We have received your message.</>,
        })
        resetForm()
      })
      .catch(() => {
        setSubmissionState({
          status: 'FAILED',
          message: (
            <>
              Your message failed to send.
              <br />
              Please check your internet connection and try again.
            </>
          ),
        })
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  return (
    <form
      name={FORM_NAME}
      method="post"
      data-netlify="true"
      data-netlify-honeypot={NETLIFY_BOT_FIELD_NAME}
      onSubmit={handleSubmit}
      aria-label="Contact"
    >
      <NetlifyHoneypot name={NETLIFY_BOT_FIELD_NAME} />

      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value={FORM_NAME} />

      <fieldset disabled={isSubmitting} className="disabled:opacity-50">
        <div
          css={css`
            display: grid;
            grid-gap: 1.5rem;

            @media (min-width: 768px) {
              grid-template-columns: 1fr 1fr;
            }
          `}
        >
          {fields.map(({ name, type, label, placeholder, required }) => {
            return (
              <Label
                key={name}
                labelText={label}
                required={required}
                style={{
                  gridColumn: type === 'textarea' ? '1 / -1' : undefined,
                }}
              >
                <Input
                  type={type}
                  name={name}
                  placeholder={placeholder}
                  required={required}
                  value={values[name]}
                  onChange={handleChange}
                />
              </Label>
            )
          })}
        </div>
      </fieldset>

      {!isSubmitting && submissionState.status !== 'UNSUBMITTED' && (
        <div className="mt-8">
          {submissionState.status === 'SUCCESS' && (
            <Alert kind="success">{submissionState.message}</Alert>
          )}

          {submissionState.status === 'FAILED' && (
            <Alert kind="error">{submissionState.message}</Alert>
          )}
        </div>
      )}

      <SubmitButton
        disabled={isSubmitting}
        style={{
          gridColumn: '1 / -1',
        }}
        className="mt-8"
      >
        {isSubmitting ? <span>Submitting&hellip;</span> : <span>Submit</span>}
      </SubmitButton>
    </form>
  )
}
