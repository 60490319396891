import { css } from '@emotion/core'
import React from 'react'
import { cx } from '../utils/helpers'
import { Address } from './company-info/Address'
import { Email } from './company-info/Email'
import { Fax } from './company-info/Fax'
import { Logomark } from './company-info/Logomark'
import { Phone } from './company-info/Phone'
import { Wordmark } from './company-info/Wordmark'
import { FeatherIcon } from './FeatherIcon'

type Props = React.HTMLAttributes<HTMLElement>

const dtClass = 'mt-8 first:mt-0 text-xl text-brand'

export function ContactInfo(props: Props): React.ReactElement {
  return (
    <article {...props}>
      <div className="flex mb-8">
        <Logomark className="w-16" />
        <Wordmark style={{ marginBottom: '-1%' }} className="w-48 ml-4" />
      </div>

      <dl
        css={css`
          dd {
            line-height: var(--leading-tight);
            margin-top: var(--space-2);
            font-size: var(--text-lg);
            padding-left: var(--space-4);

            * + * {
              margin-top: var(--space-2);
            }
          }

          a {
            display: block;

            :hover {
              color: var(--color-accent);
            }
          }
        `}
      >
        <dt className={dtClass}>Phone</dt>
        <dd>
          <Phone withAlternate={true} />
        </dd>

        <dt className={dtClass}>Email</dt>
        <dd>
          <Email />
        </dd>

        <dt className={cx('flex items-center', dtClass)}>
          <span>Address</span>
          <FeatherIcon name="external-link" size={20} className="ml-2" />
        </dt>
        <dd>
          <Address asLink={true} />
        </dd>

        <dt className={dtClass}>Fax</dt>
        <dd>
          <Fax withAlternate={true} />
        </dd>
      </dl>
    </article>
  )
}
